<template>
  <AppLoginPageLayout>
    <template>
      <h1 class="reset-password__title text-center">
        Reset your password
      </h1>
      <p class="reset-password__content mt-2 text-center">
        Leave us your email address and we'll send a link to reset your password
      </p>

      <v-form
        class="mt-8"
        @submit.prevent="resetPassword">
        <label class="sourcery__form">
          Email*
          <v-text-field
            v-model="initialCaseEmail"
            :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
            :error="!!emailErrors.length"
            :error-messages="emailErrors"
            :height="48"
            autocomplete="email"
            class="mt-2"
            dense
            outlined
            single-line
            @input="errorResetPassword = null" />
        </label>

        <v-btn
          :elevation="0"
          class="white--text sourcery__big-pill"
          color="blue"
          type="submit"
          block>
          Send
        </v-btn>
      </v-form>
    </template>
  </AppLoginPageLayout>
</template>
<script>
import {
  mapMutations, mapState,
} from 'vuex';
import {
  required, email,
} from 'vuelidate/lib/validators';
import { Auth } from 'aws-amplify';

import ErrorsText from '@/constants/errors';
import API from '@/services/graphql';

import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';

import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
export default {
  name: 'ResetPassword',
  components: {
    AppLoginPageLayout,
  },
  mixins: [ConverEmailToLowerCase],
  data: () => ({
    showPassword: false,
    errorResetPassword: null,
    pageObjName: 'reset',
    reset: {
      email: null,
      initialCaseEmail: null,
    },
  }),
  validations: {
    reset: {
      email: {
        required, email,
      },
    },
  },
  computed: {
    ...mapState(['domain']),
    emailErrors() {
      const errors = [];
      if (!this.$v.reset.email.$dirty) return errors;
      if (!this.$v.reset.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.reset.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorResetPassword) {
        errors.push(this.errorResetPassword);
      }
      return errors;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    async resetPasswordForInvitedUser() {
      const { email } = this.reset;
      try {
        await API.resetPassword({
          email,
        });
        this.$router.push({
          name: 'login', params: {
            email,
            loginInvitedUser: true,
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.spinner(false);
      }
    },
    async resetPassword() {
      this.$v.reset.$touch();
      if (!this.$v.reset.$invalid) {
        this.spinner(true);
        const { email } = this.reset;
        try {
          await Auth.forgotPassword(email, {
            domain: this.domain,
          });
          this.spinner(false);
          this.$router.push({
            name: 'confirm-reset-password', params: {
              email,
            },
          });
        } catch (err) {
          const { code, message } = err || {
          };
          if (code === 'InvalidParameterException') {
            this.spinner(false);
            this.$router.push({
              name: 'confirm-sign-up', params: {
                email, signUpBefore: true,
              },
            });
          } else if (code === 'NotAuthorizedException') {
            this.resetPasswordForInvitedUser();
          } else {
            this.spinner(false);
            this.errorResetPassword = message;
          }
        }
      }
    },
  },
};
</script>
